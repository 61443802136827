<template>
  <div class="container">
    <div class="handle-box" style="float: left;">
      <el-button icon="el-icon-refresh" @click="onRefresh">刷新</el-button>
      <el-button type="success" icon="el-icon-plus" @click="onAdd">
        添加
      </el-button>
      <el-button type="danger" icon="el-icon-delete" @click="delAll">
        批量删除
      </el-button>
    </div>
    <div style="float: right;">
      <el-form
        ref="queryForm"
        :model="query"
        :inline="true"
        label-width="120px"
      >
        <el-form-item prop="name">
          <el-input v-model="query.name" placeholder="请输入房源标题" clearable>
          </el-input>
        </el-form-item>
        <el-form-item prop="status">
          <el-select
            v-model="query.status"
            placeholder="请选择租用状态"
            clearable
          >
            <el-option label="可租" value="1"></el-option>
            <el-option label="已租" value="2"></el-option>
            <el-option label="下架" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search">
            搜索
          </el-button>
          <el-button icon="el-icon-reset" @click="resetForm('queryForm')">
            重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="clear: both;"></div>

    <!--数据表格-->
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="正在加载 ..."
      border
      fit
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="50" />
      <el-table-column type="expand">
        <template slot-scope="scope">
          <el-divider content-position="left">
            <el-tag type="primary">详细信息</el-tag>
          </el-divider>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple-dark">
                {{ scope.row.description }}
              </div>
            </el-col>
          </el-row>
          <el-divider content-position="left">
            <el-tag type="primary">租住申请条件</el-tag>
          </el-divider>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bg-purple-dark">
                {{ scope.row.conditions }}
              </div>
            </el-col>
          </el-row>
          <!--图片显示区域-->
          <template v-if="scope.row.imagesList.length > 0">
            <el-divider content-position="left">
              <el-tag>房屋图片</el-tag>
            </el-divider>
            <el-row :gutter="20">
              <el-col
                v-for="imgItem in scope.row.imagesList"
                :key="imgItem.id"
                :span="8"
              >
                <div class="img-title">
                  <h3>{{ imgItem.title }}</h3>
                </div>
                <el-popover
                  placement="top-start"
                  :title="imgItem.title"
                  width="50%"
                  trigger="click"
                >
                  <el-image :src="imgItem.url"></el-image>
                  <el-image
                    slot="reference"
                    :src="imgItem.url"
                    style="max-width: 90%;height:200px;display:block"
                  ></el-image>
                </el-popover>
              </el-col>
            </el-row>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="房源标题" prop="title" />
      <el-table-column label="详细地址" prop="address" />
      <el-table-column label="发布时间" prop="createTime" sortable />
      <el-table-column label="户型" prop="hx" />
      <el-table-column label="面积" prop="area" />
      <el-table-column label="朝向" prop="cx" />
      <el-table-column label="楼层" prop="floorStr" />
      <el-table-column label="房号" prop="roomno" />
      <el-table-column label="状态" prop="status" width="80" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 1" type="success">可租</el-tag>
          <el-tag v-if="scope.row.status === 2" type="warning">已租</el-tag>
          <el-tag v-if="scope.row.status === 3" type="info">下架</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="280" align="center">
        <template slot-scope="scope">
          <el-button type="success" size="small" @click="onEdit(scope.row)">
            编辑
          </el-button>
          <el-button type="danger" size="small" @click="onDel(scope.row)">
            删除
          </el-button>
          <el-dropdown>
            <el-button>
              操作
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="onStatus(scope.row, 1)">
                可租
              </el-dropdown-item>
              <el-dropdown-item @click.native="onStatus(scope.row, 2)">
                已租
              </el-dropdown-item>
              <el-dropdown-item @click.native="onStatus(scope.row, 3)">
                下架
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="pageInfo.startPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
    </div>

    <!--编辑信息 -->
    <el-dialog
      title="编辑信息"
      width="50%"
      :visible.sync="selectDlgShow"
      fullscreen
      center
    >
      <div
        v-loading="dlgLoading"
        class="dialog-body"
        element-loading-text="正在保存..."
      >
        <el-form ref="ruleForm" :model="form" label-width="120px">
          <el-form-item label="房源标题" prop="title" required>
            <el-input
              v-model="form.title"
              type="text"
              placeholder="请输入房源标题"
              clearable
            />
          </el-form-item>
          <el-form-item label="详细地址" prop="address" required>
            <el-input
              v-model="form.address"
              type="text"
              placeholder="请输入详细地址"
              clearable
            />
          </el-form-item>
          <el-form-item label="户型" prop="hx" required>
            <el-input
              v-model="form.hx"
              type="type"
              placeholder="请输入户型"
              clearable
            />
          </el-form-item>
          <el-form-item label="朝向" prop="cx" required>
            <el-input
              v-model="form.cx"
              type="type"
              placeholder="请输入朝向"
              clearable
            />
          </el-form-item>
          <el-form-item label="房号" prop="roomno" required>
            <el-input
              v-model="form.roomno"
              type="type"
              placeholder="请输入房号"
              clearable
            />
          </el-form-item>
          <el-form-item label="楼层" prop="floor" required>
            <el-input
              v-model="form.floor"
              type="type"
              placeholder="请输入楼层"
              clearable
            />
          </el-form-item>
          <el-form-item label="面积" prop="area" required>
            <el-input
              v-model="form.area"
              type="type"
              placeholder="请输入面积"
              clearable
            />
          </el-form-item>
          <el-form-item label="房屋描述" prop="description" required>
            <el-input
              v-model="form.description"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 8 }"
              placeholder="请输入房间配置"
              maxlength="500"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="租住申请条件" prop="conditions" required>
            <el-input
              v-model="form.conditions"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 8 }"
              placeholder="请输入租住申请条件"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="上传图片" prop="">
            <el-upload
              ref="upload"
              action=""
              :http-request="uploadFile"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :file-list="fileImageList"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="selectDlgShow = false">关 闭</el-button>
        <el-button plain @click="resetForm('ruleForm')">重 置</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">
          保存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  saveRoom,
  getRoomList,
  delRoom,
  uploadImages,
  delUploadImg,
  updateStatus
} from '@/api/renting/room';

export default {
  name: 'RentingRoom',
  data() {
    return {
      form: {
        id: '',
        title: '',
        address: '',
        hx: '',
        area: '',
        cx: '',
        floor: '',
        roomno: '',
        description: '',
        conditions: '',
        imageList: []
      },
      query: {
        name: '',
        status: ''
      },
      list: [],
      pageInfo: {
        startPage: 1,
        pageSize: 20,
        total: 0,
        name: '',
        status: ''
      },
      fileImageList: [],
      dialogImageUrl: '',
      listLoading: true,
      dlgLoading: false,
      selectDlgShow: false,
      dialogVisible: false,
      multipleSelection: []
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    onPageChange(page) {
      this.pageInfo.startPage = page;
      this.fetchData();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.fetchData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    search() {
      this.pageInfo.name = this.query.name;
      this.pageInfo.status = this.query.status;
      this.fetchData();
    },
    onRefresh() {
      this.fetchData();
    },
    //添加
    onAdd() {
      this.fileImageList = [];
      this.form = {
        id: '',
        title: '',
        address: '',
        hx: '',
        area: '',
        cx: '',
        floor: '',
        roomno: '',
        description: '',
        conditions: '',
        imageList: []
      };
      this.selectDlgShow = true;
    },
    //编辑
    onEdit(row) {
      this.fileImageList = [];
      if (row.imagesList != null && row.imagesList.length > 0) {
        this.fileImageList = row.imagesList;
      }
      this.form = {
        id: row.id,
        title: row.title,
        address: row.address,
        hx: row.hx,
        area: row.area,
        cx: row.cx,
        floor: row.floor,
        roomno: row.roomno,
        description: row.description,
        conditions: row.conditions,
        imageList: []
      };
      this.selectDlgShow = true;
    },
    //查询列表
    fetchData() {
      this.listLoading = true;
      getRoomList(this.pageInfo).then(response => {
        this.list = response.data.records;
        this.pageInfo.total = response.data.total;
        this.pageInfo.startPage = response.data.current;
        this.pageInfo.pageSize = response.data.size;
        this.listLoading = false;
      });
    },
    //批量删除
    delAll() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请至少选择一条数据');
        return;
      }
      this.$confirm('您确定要删除所选择的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var ids = [];
        this.multipleSelection.forEach(item => {
          ids.push(item.id);
        });
        delRoom({ ids: ids })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    //删除
    onDel(data) {
      this.$confirm('您确定要删除此条数据吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delRoom({ id: data.id })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    //验证表单
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.save();
        } else {
          return false;
        }
      });
    },
    //保存与修改
    save() {
      this.dlgLoading = true;
      saveRoom(this.form)
        .then(resp => {
          if (resp.code == 200) {
            this.$message({
              message: '保存成功',
              type: 'success'
            });
            this.selectDlgShow = false;
            this.dlgLoading = false;
            this.fetchData();
          } else {
            this.$message.error('保存失败');
          }
        })
        .catch(() => {
          this.dlgLoading = false;
        });
    },
    //状态修改
    onStatus(row, status) {
      updateStatus({ id: row.id, status: status }).then(resp => {
        if (resp.code == 200) {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.onRefresh();
        } else {
          this.$message.error('操作失败');
        }
      });
    },
    //图片上传
    beforeUpload() {
      if (this.form.name == '' || this.form.name == null) {
        this.$message.error('请先输入房源标题');
        return false;
      }
    },
    uploadFile(param) {
      let uploadData = new FormData();
      uploadData.append('file', param.file);
      uploadImages(uploadData).then(resp => {
        if (resp.code == 200) {
          this.$message({
            message: '上传成功',
            type: 'success'
          });
          var id = resp.data.id;
          this.form.imageList.push(id);
        } else {
          this.$message.error(resp.msg);
        }
      });
    },
    //图片删除
    handleRemove(file) {
      delUploadImg(file.id).then(response => {
        if (response.code == 200) {
          this.$message('删除成功');
        } else {
          this.$message.error('删除失败');
        }
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
.el-dropdown {
  margin-left: 10px;
}
</style>
