import request from '@/utils/request';

/**
 * 发布房源
 * @param {Object} data
 */
export function saveRoom(data) {
  return request({
    url: '/renting/manager/saveRoom',
    method: 'post',
    data
  });
}

/**
 * 查询房源列表
 * @param {Object} data
 */
export function getRoomList(data) {
  return request({
    url: '/renting/manager/getRoomList',
    method: 'post',
    data
  });
}

/**
 * 删除房源信息
 * @param {Object} data
 */
export function delRoom(data) {
  return request({
    url: '/renting/manager/delRoom',
    method: 'post',
    data
  });
}

/**
 * 上传房屋图片
 * @param {Object} data
 */
export function uploadImages(data) {
  return request({
    url: '/renting/manager/uploadImages',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  });
}

/**
 * 删除房屋图片
 * @param {Object} data
 */
export function delUploadImg(id) {
  return request({
    url: '/renting/manager/delUploadImg',
    method: 'post',
    params: {
      id: id
    }
  });
}

/**
 * 修改租房状态 （1-可租 2-已租 3-下架）
 * @param {Object} data
 */
export function updateStatus(data) {
  return request({
    url: '/renting/manager/updateStatus',
    method: 'post',
    data
  });
}
